exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accounting-accounting-js": () => import("./../../../src/pages/accounting/accounting.js" /* webpackChunkName: "component---src-pages-accounting-accounting-js" */),
  "component---src-pages-accounting-accounts-payable-service-js": () => import("./../../../src/pages/accounting/accounts-payable-service.js" /* webpackChunkName: "component---src-pages-accounting-accounts-payable-service-js" */),
  "component---src-pages-accounting-accounts-receivable-services-js": () => import("./../../../src/pages/accounting/accounts-receivable-services.js" /* webpackChunkName: "component---src-pages-accounting-accounts-receivable-services-js" */),
  "component---src-pages-accounting-business-plan-js": () => import("./../../../src/pages/accounting/business-plan.js" /* webpackChunkName: "component---src-pages-accounting-business-plan-js" */),
  "component---src-pages-accounting-due-diligence-js": () => import("./../../../src/pages/accounting/due-diligence.js" /* webpackChunkName: "component---src-pages-accounting-due-diligence-js" */),
  "component---src-pages-accounting-payroll-management-js": () => import("./../../../src/pages/accounting/payroll-management.js" /* webpackChunkName: "component---src-pages-accounting-payroll-management-js" */),
  "component---src-pages-accounting-secretarial-audit-js": () => import("./../../../src/pages/accounting/secretarial-audit.js" /* webpackChunkName: "component---src-pages-accounting-secretarial-audit-js" */),
  "component---src-pages-accounting-tax-audit-js": () => import("./../../../src/pages/accounting/tax-audit.js" /* webpackChunkName: "component---src-pages-accounting-tax-audit-js" */),
  "component---src-pages-accounting-virtual-cfo-services-js": () => import("./../../../src/pages/accounting/virtual-cfo-services.js" /* webpackChunkName: "component---src-pages-accounting-virtual-cfo-services-js" */),
  "component---src-pages-advisory-service-legal-advisory-services-js": () => import("./../../../src/pages/advisory-service/legal-advisory-services.js" /* webpackChunkName: "component---src-pages-advisory-service-legal-advisory-services-js" */),
  "component---src-pages-advisory-service-professional-advisory-services-js": () => import("./../../../src/pages/advisory-service/professional-advisory-services.js" /* webpackChunkName: "component---src-pages-advisory-service-professional-advisory-services-js" */),
  "component---src-pages-affiliate-program-js": () => import("./../../../src/pages/affiliate-program.js" /* webpackChunkName: "component---src-pages-affiliate-program-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-business-closure-closure-of-llp-js": () => import("./../../../src/pages/business-closure/closure-of-llp.js" /* webpackChunkName: "component---src-pages-business-closure-closure-of-llp-js" */),
  "component---src-pages-business-closure-closure-of-pvt-ltd-company-js": () => import("./../../../src/pages/business-closure/closure-of-pvt-ltd-company.js" /* webpackChunkName: "component---src-pages-business-closure-closure-of-pvt-ltd-company-js" */),
  "component---src-pages-business-conversion-conversion-of-llp-to-private-company-js": () => import("./../../../src/pages/business-conversion/conversion-of-llp-to-private-company.js" /* webpackChunkName: "component---src-pages-business-conversion-conversion-of-llp-to-private-company-js" */),
  "component---src-pages-business-conversion-conversion-of-private-limited-to-public-limited-js": () => import("./../../../src/pages/business-conversion/conversion-of-private-limited-to-public-limited.js" /* webpackChunkName: "component---src-pages-business-conversion-conversion-of-private-limited-to-public-limited-js" */),
  "component---src-pages-business-conversion-sole-proprietorship-to-private-limited-company-js": () => import("./../../../src/pages/business-conversion/sole-proprietorship-to-private-limited-company.js" /* webpackChunkName: "component---src-pages-business-conversion-sole-proprietorship-to-private-limited-company-js" */),
  "component---src-pages-business-reg-indian-subsidiary-company-js": () => import("./../../../src/pages/business-reg/indian-subsidiary-company.js" /* webpackChunkName: "component---src-pages-business-reg-indian-subsidiary-company-js" */),
  "component---src-pages-business-reg-limited-liability-partnership-js": () => import("./../../../src/pages/business-reg/limited-liability-partnership.js" /* webpackChunkName: "component---src-pages-business-reg-limited-liability-partnership-js" */),
  "component---src-pages-business-reg-nidhi-company-registration-js": () => import("./../../../src/pages/business-reg/nidhi-company-registration.js" /* webpackChunkName: "component---src-pages-business-reg-nidhi-company-registration-js" */),
  "component---src-pages-business-reg-one-person-company-js": () => import("./../../../src/pages/business-reg/one-person-company.js" /* webpackChunkName: "component---src-pages-business-reg-one-person-company-js" */),
  "component---src-pages-business-reg-partnership-firm-registration-js": () => import("./../../../src/pages/business-reg/partnership-firm-registration.js" /* webpackChunkName: "component---src-pages-business-reg-partnership-firm-registration-js" */),
  "component---src-pages-business-reg-private-limited-company-js": () => import("./../../../src/pages/business-reg/private-limited-company.js" /* webpackChunkName: "component---src-pages-business-reg-private-limited-company-js" */),
  "component---src-pages-business-reg-producer-company-js": () => import("./../../../src/pages/business-reg/producer-company.js" /* webpackChunkName: "component---src-pages-business-reg-producer-company-js" */),
  "component---src-pages-business-reg-sole-proprietorship-registration-js": () => import("./../../../src/pages/business-reg/sole-proprietorship-registration.js" /* webpackChunkName: "component---src-pages-business-reg-sole-proprietorship-registration-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-event-compliances-appointment-and-resignation-of-directors-js": () => import("./../../../src/pages/event-compliances/appointment-and-resignation-of-directors.js" /* webpackChunkName: "component---src-pages-event-compliances-appointment-and-resignation-of-directors-js" */),
  "component---src-pages-event-compliances-change-in-name-clause-js": () => import("./../../../src/pages/event-compliances/change-in-name-clause.js" /* webpackChunkName: "component---src-pages-event-compliances-change-in-name-clause-js" */),
  "component---src-pages-event-compliances-change-in-object-clause-js": () => import("./../../../src/pages/event-compliances/change-in-object-clause.js" /* webpackChunkName: "component---src-pages-event-compliances-change-in-object-clause-js" */),
  "component---src-pages-event-compliances-change-in-share-capital-js": () => import("./../../../src/pages/event-compliances/change-in-share-capital.js" /* webpackChunkName: "component---src-pages-event-compliances-change-in-share-capital-js" */),
  "component---src-pages-event-compliances-change-of-registered-office-js": () => import("./../../../src/pages/event-compliances/change-of-registered-office.js" /* webpackChunkName: "component---src-pages-event-compliances-change-of-registered-office-js" */),
  "component---src-pages-event-compliances-event-based-compliances-in-a-company-js": () => import("./../../../src/pages/event-compliances/event-based-compliances-in-a-company.js" /* webpackChunkName: "component---src-pages-event-compliances-event-based-compliances-in-a-company-js" */),
  "component---src-pages-event-compliances-remove-directors-js": () => import("./../../../src/pages/event-compliances/remove-directors.js" /* webpackChunkName: "component---src-pages-event-compliances-remove-directors-js" */),
  "component---src-pages-gst-gst-advisory-services-js": () => import("./../../../src/pages/gst/gst-advisory-services.js" /* webpackChunkName: "component---src-pages-gst-gst-advisory-services-js" */),
  "component---src-pages-gst-gst-annual-return-filing-js": () => import("./../../../src/pages/gst/gst-annual-return-filing.js" /* webpackChunkName: "component---src-pages-gst-gst-annual-return-filing-js" */),
  "component---src-pages-gst-gst-lut-filing-js": () => import("./../../../src/pages/gst/gst-lut-filing.js" /* webpackChunkName: "component---src-pages-gst-gst-lut-filing-js" */),
  "component---src-pages-gst-gst-registration-cancellation-js": () => import("./../../../src/pages/gst/gst-registration-cancellation.js" /* webpackChunkName: "component---src-pages-gst-gst-registration-cancellation-js" */),
  "component---src-pages-gst-gst-registration-js": () => import("./../../../src/pages/gst/gst-registration.js" /* webpackChunkName: "component---src-pages-gst-gst-registration-js" */),
  "component---src-pages-gst-gst-return-filing-js": () => import("./../../../src/pages/gst/gst-return-filing.js" /* webpackChunkName: "component---src-pages-gst-gst-return-filing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ipr-copyright-registration-js": () => import("./../../../src/pages/ipr/copyright-registration.js" /* webpackChunkName: "component---src-pages-ipr-copyright-registration-js" */),
  "component---src-pages-ipr-design-registration-js": () => import("./../../../src/pages/ipr/design-registration.js" /* webpackChunkName: "component---src-pages-ipr-design-registration-js" */),
  "component---src-pages-ipr-intellectual-property-dispute-js": () => import("./../../../src/pages/ipr/intellectual-property-dispute.js" /* webpackChunkName: "component---src-pages-ipr-intellectual-property-dispute-js" */),
  "component---src-pages-ipr-international-trade-mark-registration-js": () => import("./../../../src/pages/ipr/international-trade-mark-registration.js" /* webpackChunkName: "component---src-pages-ipr-international-trade-mark-registration-js" */),
  "component---src-pages-ipr-patent-registration-js": () => import("./../../../src/pages/ipr/patent-registration.js" /* webpackChunkName: "component---src-pages-ipr-patent-registration-js" */),
  "component---src-pages-ipr-trademark-assignment-js": () => import("./../../../src/pages/ipr/trademark-assignment.js" /* webpackChunkName: "component---src-pages-ipr-trademark-assignment-js" */),
  "component---src-pages-ipr-trademark-objection-js": () => import("./../../../src/pages/ipr/trademark-objection.js" /* webpackChunkName: "component---src-pages-ipr-trademark-objection-js" */),
  "component---src-pages-ipr-trademark-rectification-js": () => import("./../../../src/pages/ipr/trademark-rectification.js" /* webpackChunkName: "component---src-pages-ipr-trademark-rectification-js" */),
  "component---src-pages-ipr-trademark-registration-js": () => import("./../../../src/pages/ipr/trademark-registration.js" /* webpackChunkName: "component---src-pages-ipr-trademark-registration-js" */),
  "component---src-pages-ipr-trademark-renewal-js": () => import("./../../../src/pages/ipr/trademark-renewal.js" /* webpackChunkName: "component---src-pages-ipr-trademark-renewal-js" */),
  "component---src-pages-itr-filing-js": () => import("./../../../src/pages/itr-filing.js" /* webpackChunkName: "component---src-pages-itr-filing-js" */),
  "component---src-pages-mortgages-hra-calculator-js": () => import("./../../../src/pages/mortgages/hra-calculator.js" /* webpackChunkName: "component---src-pages-mortgages-hra-calculator-js" */),
  "component---src-pages-mortgages-income-tax-calculator-js": () => import("./../../../src/pages/mortgages/income-tax-calculator.js" /* webpackChunkName: "component---src-pages-mortgages-income-tax-calculator-js" */),
  "component---src-pages-mortgages-interest-calculator-js": () => import("./../../../src/pages/mortgages/interest-calculator.js" /* webpackChunkName: "component---src-pages-mortgages-interest-calculator-js" */),
  "component---src-pages-mortgages-itr-eligibility-checker-js": () => import("./../../../src/pages/mortgages/itr-eligibility-checker.js" /* webpackChunkName: "component---src-pages-mortgages-itr-eligibility-checker-js" */),
  "component---src-pages-mortgages-itrfiling-js": () => import("./../../../src/pages/mortgages/itrfiling.js" /* webpackChunkName: "component---src-pages-mortgages-itrfiling-js" */),
  "component---src-pages-mortgages-rent-receipt-generator-js": () => import("./../../../src/pages/mortgages/rent-receipt-generator.js" /* webpackChunkName: "component---src-pages-mortgages-rent-receipt-generator-js" */),
  "component---src-pages-mortgages-tax-calculator-js": () => import("./../../../src/pages/mortgages/tax-calculator.js" /* webpackChunkName: "component---src-pages-mortgages-tax-calculator-js" */),
  "component---src-pages-mortgages-tds-calculator-js": () => import("./../../../src/pages/mortgages/tds-calculator.js" /* webpackChunkName: "component---src-pages-mortgages-tds-calculator-js" */),
  "component---src-pages-ngo-12-aa-registration-js": () => import("./../../../src/pages/ngo/12aa-registration.js" /* webpackChunkName: "component---src-pages-ngo-12-aa-registration-js" */),
  "component---src-pages-ngo-80-c-80-deductions-js": () => import("./../../../src/pages/ngo/80c-80-deductions.js" /* webpackChunkName: "component---src-pages-ngo-80-c-80-deductions-js" */),
  "component---src-pages-ngo-80-g-and-12-a-registration-js": () => import("./../../../src/pages/ngo/80g-and-12a-registration.js" /* webpackChunkName: "component---src-pages-ngo-80-g-and-12-a-registration-js" */),
  "component---src-pages-ngo-fcra-registration-js": () => import("./../../../src/pages/ngo/fcra-registration.js" /* webpackChunkName: "component---src-pages-ngo-fcra-registration-js" */),
  "component---src-pages-ngo-ngo-registration-js": () => import("./../../../src/pages/ngo/ngo-registration.js" /* webpackChunkName: "component---src-pages-ngo-ngo-registration-js" */),
  "component---src-pages-ngo-section-8-company-js": () => import("./../../../src/pages/ngo/section-8-company.js" /* webpackChunkName: "component---src-pages-ngo-section-8-company-js" */),
  "component---src-pages-ngo-society-registration-js": () => import("./../../../src/pages/ngo/society-registration.js" /* webpackChunkName: "component---src-pages-ngo-society-registration-js" */),
  "component---src-pages-ngo-trust-registration-js": () => import("./../../../src/pages/ngo/trust-registration.js" /* webpackChunkName: "component---src-pages-ngo-trust-registration-js" */),
  "component---src-pages-pricing-submit-js": () => import("./../../../src/pages/pricing-submit.js" /* webpackChunkName: "component---src-pages-pricing-submit-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-registration-digital-signature-certificate-js": () => import("./../../../src/pages/registration/digital-signature-certificate.js" /* webpackChunkName: "component---src-pages-registration-digital-signature-certificate-js" */),
  "component---src-pages-registration-food-license-js": () => import("./../../../src/pages/registration/food-license.js" /* webpackChunkName: "component---src-pages-registration-food-license-js" */),
  "component---src-pages-registration-import-export-code-js": () => import("./../../../src/pages/registration/import-export-code.js" /* webpackChunkName: "component---src-pages-registration-import-export-code-js" */),
  "component---src-pages-registration-iso-registration-js": () => import("./../../../src/pages/registration/iso-registration.js" /* webpackChunkName: "component---src-pages-registration-iso-registration-js" */),
  "component---src-pages-registration-msme-registration-js": () => import("./../../../src/pages/registration/msme-registration.js" /* webpackChunkName: "component---src-pages-registration-msme-registration-js" */),
  "component---src-pages-registration-professional-tax-registration-js": () => import("./../../../src/pages/registration/professional-tax-registration.js" /* webpackChunkName: "component---src-pages-registration-professional-tax-registration-js" */),
  "component---src-pages-registration-shop-establishment-license-js": () => import("./../../../src/pages/registration/shop-establishment-license.js" /* webpackChunkName: "component---src-pages-registration-shop-establishment-license-js" */),
  "component---src-pages-secretarial-compliances-annual-compliance-of-a-private-limited-company-js": () => import("./../../../src/pages/secretarial-compliances/annual-compliance-of-a-private-limited-company.js" /* webpackChunkName: "component---src-pages-secretarial-compliances-annual-compliance-of-a-private-limited-company-js" */),
  "component---src-pages-secretarial-compliances-annual-filing-for-llp-js": () => import("./../../../src/pages/secretarial-compliances/annual-filing-for-llp.js" /* webpackChunkName: "component---src-pages-secretarial-compliances-annual-filing-for-llp-js" */),
  "component---src-pages-secretarial-compliances-nbfc-compliance-js": () => import("./../../../src/pages/secretarial-compliances/nbfc-compliance.js" /* webpackChunkName: "component---src-pages-secretarial-compliances-nbfc-compliance-js" */),
  "component---src-pages-secretarial-compliances-nidhi-company-compliance-js": () => import("./../../../src/pages/secretarial-compliances/nidhi-company-compliance.js" /* webpackChunkName: "component---src-pages-secretarial-compliances-nidhi-company-compliance-js" */),
  "component---src-pages-secretarial-compliances-section-8-company-compliance-js": () => import("./../../../src/pages/secretarial-compliances/section-8-company-compliance.js" /* webpackChunkName: "component---src-pages-secretarial-compliances-section-8-company-compliance-js" */),
  "component---src-pages-taxation-income-tax-return-js": () => import("./../../../src/pages/taxation/income-tax-return.js" /* webpackChunkName: "component---src-pages-taxation-income-tax-return-js" */),
  "component---src-pages-taxation-pf-return-filing-js": () => import("./../../../src/pages/taxation/pf-return-filing.js" /* webpackChunkName: "component---src-pages-taxation-pf-return-filing-js" */),
  "component---src-pages-taxation-tds-return-filing-js": () => import("./../../../src/pages/taxation/tds-return-filing.js" /* webpackChunkName: "component---src-pages-taxation-tds-return-filing-js" */)
}

